import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Lenis from "lenis";
import Swiper from "swiper";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import * as CookieConsent from "vanilla-cookieconsent";

gsap.registerPlugin(ScrollTrigger);

// Lenis - smooth scrolling.

const lenis = new Lenis({ lerp: 0.04 });
lenis.on("scroll", () => ScrollTrigger.update());
const scrollFn = (time) => {
  lenis.raf(time);
  requestAnimationFrame(scrollFn);
};
requestAnimationFrame(scrollFn);

// Preloader

window.addEventListener("load", () => {
  const preloader = document.querySelector(".preloader");
  preloader.classList.remove("active");
  setTimeout(() => {
    preloader.remove();
  }, 1000);
});

// Smooth anchor scrolling.

document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
  anchor.addEventListener("click", function (e) {
    e.preventDefault();
    var href = this.getAttribute("href");
    lenis.scrollTo(href);
  });
});

// Header scroll show/hide logic

var scrollBefore = 0;

window.addEventListener("scroll", function () {
  var header = document.querySelector("header");

  let mm = gsap.matchMedia();

  mm.add("(min-width: 1024px)", () => {
    const scrolled = window.scrollY;

    if (scrolled > 80) {
      header.classList.add("moved");

      if (scrollBefore > scrolled) {
        scrollBefore = scrolled;
        header.classList.remove("scrolled");
      } else {
        scrollBefore = scrolled;
        header.classList.add("scrolled");
        var submenus = document.querySelectorAll(".menu-submenu"),
          toggles = document.querySelectorAll(".submenu-toggle");
        submenus.forEach((submenu) => {
          submenu.classList.remove("active");
        });
        toggles.forEach((item) => {
          item.classList.remove("active");
        });
      }
    } else {
      scrollBefore = scrolled;
      header.classList.remove("scrolled");
      header.classList.remove("moved");
    }
  });
});

// Menu toggles

var toggles = document.querySelectorAll(".submenu-toggle"),
  submenus = document.querySelectorAll(".menu-submenu");

if (toggles) {
  toggles.forEach((toggle) => {
    toggle.addEventListener("click", () => {
      var target = toggle.dataset.toggle;

      toggles.forEach((item) => {
        if (item.dataset.toggle === target) {
          if (item.classList.contains("active")) {
            item.classList.remove("active");
          } else {
            item.classList.add("active");
          }
        } else {
          item.classList.remove("active");
        }
      });

      submenus.forEach((submenu) => {
        if (submenu.dataset.id === target) {
          if (submenu.classList.contains("active")) {
            submenu.classList.remove("active");
          } else {
            submenu.classList.add("active");
          }
        } else {
          submenu.classList.remove("active");
        }
      });
    });
  });
}

// Mobile menu toggle

let mobileMenuToggle = document.querySelector(".mobile-menu-toggle");
let mobileMenu = document.querySelector(".mobile-menu");
mobileMenuToggle.addEventListener("click", () => {
  mobileMenuToggle.classList.toggle("active");
  if (mobileMenu.classList.contains("active")) {
    mobileMenu.classList.remove("active");
  } else {
    mobileMenu.classList.add("active");
  }
});

// Scroll galleries

var scrollGalleries = gsap.utils.toArray("[data-scroll-gallery]");

if (scrollGalleries) {
  scrollGalleries.forEach((scrollGallery) => {
    var direction = scrollGallery.dataset.direction;

    if (direction == "left") {
      gsap.set(scrollGallery, { x: window.innerWidth });
      scrollGallery.x = -(scrollGallery.scrollWidth - window.innerWidth);
    } else {
      gsap.set(scrollGallery, { x: -window.innerWidth });
      scrollGallery.x = scrollGallery.scrollWidth - window.innerWidth;
    }

    gsap.to(scrollGallery, {
      ease: "none",
      x: () => scrollGallery.x,
      scrollTrigger: {
        trigger: scrollGallery,
        start: "top bottom",
        end: "bottom center",
        scrub: 4,
        invalidateOnRefresh: true,
      },
    });
  });
}

// Hero slider

var heroSliders = document.querySelectorAll(".hero-swiper");

if (heroSliders) {
  heroSliders.forEach((slider) => {
    var heroSwiper = new Swiper(slider, {
      autoplay: {
        delay: 6000,
        disableOnInteraction: false,
      },
      centeredSlides: true,
      modules: [Autoplay],
      navigation: false,
      pagination: false,
    });
  });
}

// Line drawing animations

var heroSwipers = document.querySelectorAll(".hero-swiper .swiper-slide");

if (heroSwipers) {
  window.addEventListener("load", () => {
    var heroCount = 0;

    heroSwipers.forEach((heroSwiper) => {
      if (0 == heroCount) {
        var lineDrawing = heroSwiper.querySelector(".line-drawing"),
          lineTarget = heroSwiper.querySelector(".line-drawing svg path");

        if (lineDrawing && lineTarget) {
          lineDrawing.classList.add("active");

          var length = lineTarget.getTotalLength();

          lineTarget.style.transition = lineTarget.style.WebkitTransition =
            "none";
          lineTarget.style.strokeDasharray = length + " " + length;
          lineTarget.style.strokeDashoffset = length;
          lineTarget.getBoundingClientRect();
          lineTarget.style.transition = lineTarget.style.WebkitTransition =
            "stroke-dashoffset 3s ease-in-out";
          lineTarget.style.strokeDashoffset = "0";
        }
      }

      heroCount++;
    });
  });

  const attrObserver = new MutationObserver((mutations) => {
    mutations.forEach((mu) => {
      if (mu.type !== "attributes" && mu.attributeName !== "class") return;
      if (mu.target.classList.contains("swiper-slide-active")) {
        var lineDrawing = mu.target.querySelector(".line-drawing"),
          lineTarget = mu.target.querySelector(".line-drawing svg path"),
          length = lineTarget.getTotalLength();

        lineDrawing.classList.add("active");

        lineTarget.style.transition = lineTarget.style.WebkitTransition =
          "none";
        lineTarget.style.strokeDasharray = length + " " + length;
        lineTarget.style.strokeDashoffset = length;
        lineTarget.getBoundingClientRect();
        lineTarget.style.transition = lineTarget.style.WebkitTransition =
          "stroke-dashoffset 3s ease-in-out";
        lineTarget.style.strokeDashoffset = "0";
      }
    });
  });

  heroSwipers.forEach((el) => attrObserver.observe(el, { attributes: true }));
}

// Case study slider

var caseSliders = document.querySelectorAll(".case-swiper");

if (caseSliders) {
  caseSliders.forEach((slider) => {
    var caseSwiper = new Swiper(slider, {
      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
      },
      centeredSlides: true,
      modules: [Autoplay, Pagination],
      navigation: false,
      pagination: {
        clickable: true,
        el: ".case-navigation",
      },
    });
  });
}

// Line drawings

var lineAnimations = document.querySelectorAll(".animate-line");

if (lineAnimations) {
  lineAnimations.forEach((lineAnimation) => {
    const observer = new IntersectionObserver((entries) => {
      var lineDrawing = lineAnimation.querySelector(".line-drawing"),
        lineTarget = lineAnimation.querySelector(".line-drawing svg path");

      if (lineDrawing && lineTarget) {
        lineDrawing.classList.add("active");

        var length = lineTarget.getTotalLength();

        lineTarget.style.transition = lineTarget.style.WebkitTransition =
          "none";
        lineTarget.style.strokeDasharray = length + " " + length;
        lineTarget.style.strokeDashoffset = length;
        lineTarget.getBoundingClientRect();
        lineTarget.style.transition = lineTarget.style.WebkitTransition =
          "stroke-dashoffset 3s ease-in-out";
        lineTarget.style.strokeDashoffset = "0";
      }
    });

    observer.observe(lineAnimation);
  });
}

// Scale images

var scaleImages = document.querySelectorAll(".scale-image");

if (scaleImages) {

  const scaleImageObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {

          if (entry.isIntersecting) {
            
            entry.target.classList.add('active');

          } else {

            entry.target.classList.remove('active');

          }
          
        });
      },
      {
        rootMargin: "0px 0px 0px",
      }
    );

    scaleImages.forEach((scaleImage) => {
      scaleImageObserver.observe(scaleImage);
    });

}

// FAQ toggle

var faqToggles = document.querySelectorAll(".faq-toggle"),
  faqContents = document.querySelectorAll(".faq-content");

if (faqToggles) {
  faqToggles.forEach((faqToggle) => {
    faqToggle.addEventListener("click", () => {
      var targetId = faqToggle.dataset.id;

      faqToggles.forEach((item) => {
        if (item.dataset.id != targetId) {
          item.classList.remove("active");
        }
      });

      faqContents.forEach((item) => {
        if (item.dataset.id != targetId) {
          item.style.maxHeight = null;
          item.classList.remove("active");
        }
      });

      document
        .querySelector('.faq-toggle[data-id="' + targetId + '"]')
        .classList.toggle("active");

      var targetContent = document.querySelector(
        '.faq-content[data-id="' + targetId + '"]'
      );

      if (
        targetContent.style.maxHeight != null &&
        targetContent.style.maxHeight != ""
      ) {
        targetContent.style.maxHeight = null;
        targetContent.classList.remove("active");
      } else {
        targetContent.style.maxHeight = targetContent.scrollHeight + "px";
        targetContent.classList.add("active");
      }
    });
  });
}

// Post ajax loading

var postCategoryFilters = document.querySelectorAll(".post-category-filter");

if (postCategoryFilters) {
  postCategoryFilters.forEach((postCategoryFilter) => {
    postCategoryFilter.addEventListener("click", () => {
      var targetId = postCategoryFilter.dataset.id;

      postCategoryFilters.forEach((item) => {
        if (item.dataset.id != targetId) {
          item.classList.remove("active");
        } else {
          if (item.classList.contains("active")) {
            item.classList.remove("active");
          } else {
            item.classList.add("active");
          }
        }
      });

      var currentActiveCategory = document.querySelector(
        ".post-category-filter.active"
      );

      if (currentActiveCategory) {
        var activeCategory = currentActiveCategory.dataset.id;
      } else {
        var activeCategory = false;
      }

      var currentActiveTopic = document.querySelector(
        ".post-tag-filter.active"
      );

      if (currentActiveTopic) {
        var activeTopic = currentActiveTopic.dataset.id;
      } else {
        var activeTopic = false;
      }

      var page = 0,
        data = [
          {
            page: page,
            category: activeCategory,
            topic: activeTopic,
          },
        ];

      postLoader(data, "replace");
    });
  });
}

var postTagFilters = document.querySelectorAll(".post-tag-filter");

if (postTagFilters) {
  postTagFilters.forEach((postTagFilter) => {
    postTagFilter.addEventListener("click", () => {
      var targetId = postTagFilter.dataset.id;

      postTagFilters.forEach((item) => {
        if (item.dataset.id != targetId) {
          item.classList.remove("active");
        } else {
          if (item.classList.contains("active")) {
            item.classList.remove("active");
          } else {
            item.classList.add("active");
          }
        }
      });

      var currentActiveCategory = document.querySelector(
        ".post-category-filter.active"
      );

      if (currentActiveCategory) {
        var activeCategory = currentActiveCategory.dataset.id;
      } else {
        var activeCategory = false;
      }

      var currentActiveTopic = document.querySelector(
        ".post-tag-filter.active"
      );

      if (currentActiveTopic) {
        var activeTopic = currentActiveTopic.dataset.id;
      } else {
        var activeTopic = false;
      }

      var page = 0,
        data = [
          {
            page: page,
            category: activeCategory,
            topic: activeTopic,
          },
        ];

      postLoader(data, "replace");
    });
  });
}

document.body.addEventListener("click", function (event) {
  if (event.target.id == "post-loader") {
    var page = event.target.dataset.page,
      postCategoryFilters = document.querySelectorAll(".post-category-filter"),
      postTagFilters = document.querySelectorAll(".post-tag-filter"),
      archiveId = document.getElementById("post-target");

    if (postCategoryFilters) {
      var currentActiveCategory = document.querySelectorAll(
        ".post-category-filter.active"
      );

      if (currentActiveCategory.length > 0) {
        var activeCategory = currentActiveCategory.dataset.id;
      } else {
        if (archiveId) {
          var activeCategory = archiveId.dataset.id;
        } else {
          var activeCategory = false;
        }
        var activeCategory = false;
      }
    } else {
      if (archiveId) {
        var activeCategory = archiveId.dataset.id;
      } else {
        var activeCategory = false;
      }
    }

    if (postTagFilters) {
      var currentActiveCTopic = document.querySelectorAll(
        ".post-tag-filter.active"
      );

      if (currentActiveCTopic.length > 0) {
        var activeTopic = currentActiveCTopic.dataset.id;
      } else {
        var activeTopic = false;
      }
    } else {
      var activeTopic = false;
    }

    var data = [
      {
        page: page,
        category: activeCategory,
        topic: activeTopic,
      },
    ];

    postLoader(data, "amend");
  }
});

function postLoader(data, containerAction) {
  var xhttp = new XMLHttpRequest(),
    postLoaderButton = document.getElementById("post-loader"),
    postTarget = document.getElementById("post-target");

  xhttp.open("POST", "/wp-admin/admin-ajax.php", true);
  xhttp.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
  xhttp.send("action=post_loader&data=" + JSON.stringify(data));

  xhttp.onreadystatechange = function () {
    if (xhttp.readyState === 4 && xhttp.status === 200) {
      if (postLoaderButton) {
        postLoaderButton.parentElement.remove();
      }

      if (containerAction == "amend") {
        postTarget.insertAdjacentHTML("beforeend", xhttp.responseText);
      } else {
        postTarget.innerHTML = "";
        postTarget.innerHTML = xhttp.responseText;
      }

      lazyLoader()
      gsapAnimations();
    }
  };
}

// Case study ajax loading

var caseCategoryFilters = document.querySelectorAll(".case-category-filter");

if (caseCategoryFilters) {
  caseCategoryFilters.forEach((caseCategoryFilter) => {
    caseCategoryFilter.addEventListener("click", () => {
      var targetId = caseCategoryFilter.dataset.id;

      caseCategoryFilters.forEach((item) => {
        if (item.dataset.id != targetId) {
          item.classList.remove("active");
        } else {
          if (item.classList.contains("active")) {
            item.classList.remove("active");
          } else {
            item.classList.add("active");
          }
        }
      });

      var currentActiveCategory = document.querySelector(
        ".case-category-filter.active"
      );

      if (currentActiveCategory) {
        var activeCategory = currentActiveCategory.dataset.id;
      } else {
        var activeCategory = false;
      }

      var currentActiveTopic = document.querySelector(
        ".case-topic-filter.active"
      );

      if (currentActiveTopic) {
        var activeTopic = currentActiveTopic.dataset.id;
      } else {
        var activeTopic = false;
      }

      var page = 0,
        data = [
          {
            page: page,
            category: activeCategory,
            topic: activeTopic,
          },
        ];

      caseLoader(data, "replace");
    });
  });
}

var caseTopicFilters = document.querySelectorAll(".case-topic-filter");

if (caseTopicFilters) {
  caseTopicFilters.forEach((caseTopicFilter) => {
    caseTopicFilter.addEventListener("click", () => {
      var targetId = caseTopicFilter.dataset.id;

      caseTopicFilters.forEach((item) => {
        if (item.dataset.id != targetId) {
          item.classList.remove("active");
        } else {
          if (item.classList.contains("active")) {
            item.classList.remove("active");
          } else {
            item.classList.add("active");
          }
        }
      });

      var currentActiveCategory = document.querySelector(
        ".case-category-filter.active"
      );

      if (currentActiveCategory) {
        var activeCategory = currentActiveCategory.dataset.id;
      } else {
        var activeCategory = false;
      }

      var currentActiveTopic = document.querySelector(
        ".case-topic-filter.active"
      );

      if (currentActiveTopic) {
        var activeTopic = currentActiveTopic.dataset.id;
      } else {
        var activeTopic = false;
      }

      var page = 0,
        data = [
          {
            page: page,
            category: activeCategory,
            topic: activeTopic,
          },
        ];

      caseLoader(data, "replace");
    });
  });
}

document.body.addEventListener("click", function (event) {
  if (event.target.id == "case-loader") {
    var page = event.target.dataset.page,
      caseCategoryFilters = document.querySelectorAll(".case-category-filter"),
      caseTopicFilters = document.querySelectorAll(".case-topic-filter");

    if (caseCategoryFilters) {
      var currentActiveCategory = document.querySelectorAll(
        ".case-category-filter.active"
      );

      if (currentActiveCategory.length > 0) {
        var activeCategory = currentActiveCategory.dataset.id;
      } else {
        var activeCategory = false;
      }
    } else {
      var activeCategory = false;
    }

    if (caseTopicFilters) {
      var currentActiveCTopic = document.querySelectorAll(
        ".case-topic-filter.active"
      );

      if (currentActiveCTopic.length > 0) {
        var activeTopic = currentActiveCTopic.dataset.id;
      } else {
        var activeTopic = false;
      }
    } else {
      var activeTopic = false;
    }

    var data = [
      {
        page: page,
        category: activeCategory,
        topic: activeTopic,
      },
    ];

    caseLoader(data, "amend");
  }
});

function caseLoader(data, containerAction) {
  var xhttp = new XMLHttpRequest(),
    caseLoaderButton = document.getElementById("case-loader"),
    caseTarget = document.getElementById("case-target");

  xhttp.open("POST", "/wp-admin/admin-ajax.php", true);
  xhttp.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
  xhttp.send("action=case_loader&data=" + JSON.stringify(data));

  xhttp.onreadystatechange = function () {
    if (xhttp.readyState === 4 && xhttp.status === 200) {
      if (caseLoaderButton) {
        caseLoaderButton.parentElement.remove();
      }

      if (containerAction == "amend") {
        caseTarget.insertAdjacentHTML("beforeend", xhttp.responseText);
      } else {
        caseTarget.innerHTML = "";
        caseTarget.innerHTML = xhttp.responseText;
      }

      lazyLoader()
      gsapAnimations();
    }
  };
}

// GSAP animations

function gsapAnimations() {
  ScrollTrigger.defaults({
    toggleActions: "restart pause resume pause",
  });

  var animatedSections = gsap.utils.toArray("[data-animate]");

  if (animatedSections) {
    animatedSections.forEach((animatedSection) => {
      var slideLeftItems =
          animatedSection.querySelectorAll("[data-slide-left]"),
        slideRightItems =
          animatedSection.querySelectorAll("[data-slide-right]"),
        slideUpItems = animatedSection.querySelectorAll("[data-slide-up]"),
        fadeUpItems = animatedSection.querySelectorAll("[data-fade-up]");

      slideLeftItems.forEach((item) => {
        gsap.set(item, {
          x: -100,
          opacity: 0,
        });
        gsap.to(item, {
          scrollTrigger: {
            trigger: animatedSection,
            toggleActions: "restart",
          },
          x: 0,
          opacity: 1,
        });
      });

      slideRightItems.forEach((item) => {
        gsap.set(item, {
          x: 100,
          opacity: 0,
        });
        gsap.to(item, {
          scrollTrigger: {
            trigger: animatedSection,
            toggleActions: "restart",
          },
          x: 0,
          opacity: 1,
        });
      });

      slideUpItems.forEach((item) => {
        gsap.set(item, {
          y: 100,
          opacity: 0,
        });
        gsap.to(item, {
          scrollTrigger: {
            trigger: animatedSection,
            toggleActions: "restart",
          },
          y: 0,
          opacity: 1,
        });
      });

      fadeUpItems.forEach((item) => {
        gsap.set(item, {
          opacity: 0,
        });
        gsap.to(item, {
          scrollTrigger: {
            trigger: animatedSection,
            toggleActions: "restart",
          },
          opacity: 1,
        });
      });
    });
  }
}

// Lazy load assets

function lazyLoader() {
  let lazyImages = document.querySelectorAll(".lazy-image"),
    lazyVideos = document.querySelectorAll(".lazy-video");

  if ("IntersectionObserver" in window) {
    let lazyImageObserver = new IntersectionObserver(function (
      entries,
      observer
    ) {
      entries.forEach(function (entry) {
        if (entry.isIntersecting) {
          let lazyImage = entry.target;

          lazyImage.src = lazyImage.dataset.src;

          if (lazyImage.dataset.srcset) {
            lazyImage.srcset = lazyImage.dataset.srcset;
          }

          lazyImage.classList.remove("lazy-image");
          lazyImageObserver.unobserve(lazyImage);
        }
      });
    });

    lazyImages.forEach(function (lazyImage) {
      lazyImageObserver.observe(lazyImage);
    });

    let lazyVideoObserver = new IntersectionObserver(function (
      entries,
      observer
    ) {
      entries.forEach(function (video) {
        if (video.isIntersecting) {
          for (let source in video.target.children) {
            let videoSource = video.target.children[source];

            if (
              typeof videoSource.tagName === "string" &&
              videoSource.tagName === "SOURCE"
            ) {
              videoSource.src = videoSource.dataset.src;
            }
          }

          video.target.load();
          video.target.classList.remove("lazy");
          lazyVideoObserver.unobserve(video.target);
        }
      });
    });

    lazyVideos.forEach(function (lazyVideo) {
      lazyVideoObserver.observe(lazyVideo);
    });
  }
}

window.addEventListener("load", () => {
  lazyLoader();
  gsapAnimations();
});

// Cookie consent

window.addEventListener("load", () => {
  CookieConsent.run({
    guiOptions: {
      consentModal: {
        layout: "box",
        position: "bottom left",
        equalWeightButtons: true,
        flipButtons: false,
      },
      preferencesModal: {
        layout: "box",
        position: "right",
        equalWeightButtons: true,
        flipButtons: false,
      },
    },
    categories: {
      necessary: {
        readOnly: true,
      },
      functionality: {},
      analytics: {},
    },
    language: {
      default: "en",
      autoDetect: "browser",
      translations: {
        en: {
          consentModal: {
            title: "Manage your cookies",
            description: "",
            acceptAllBtn: "Accept all",
            acceptNecessaryBtn: "Reject all",
            showPreferencesBtn: "Manage",
          },
          preferencesModal: {
            title: "Consent Preferences",
            acceptAllBtn: "Accept all",
            acceptNecessaryBtn: "Reject all",
            savePreferencesBtn: "Save preferences",
            closeIconLabel: "Close modal",
            serviceCounterLabel: "Service|Services",
            sections: [
              {
                title: "Welcome visitor!",
                description: "Select your cookie preferences below.",
              },
              {
                title: "Strictly Necessary cookies",
                description:
                  "These cookies are essential for the proper functioning of the website and cannot be disabled.",
                linkedCategory: "necessary",
              },
              {
                title: "Performance and Analytics",
                description:
                  "These cookies collect information about how you use our website. All of the data is anonymized and cannot be used to identify you.",
                linkedCategory: "analytics",
              },
              {
                title: "Functionality Cookies",
                description:
                  "These cookies control the look and functionality of this website. Disabling these can effect your experience of this website.",
                linkedCategory: "functionality",
              },
              {
                title: "More information",
                description:
                  "For any queries in relation to our cookie policy and your choices, please contact our team.",
              },
            ],
          },
        },
      },
    },
  });
});
